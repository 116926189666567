import React, { useEffect, useState } from "react";

const SecurityDashboard = () => {
  return (
    <iframe
      width="100%"
      height="93%"
      src="https://app.powerbi.com/view?r=eyJrIjoiOTE5MTZlMWEtYmRiNi00NzRjLTk1NTYtZWJiYTQ2MWU5NWVlIiwidCI6IjUyMThlM2IxLWM0YmEtNDcwNi05OWY5LWE2YjBkNTU0OThiNCJ9"
    ></iframe>
  );
};

export default SecurityDashboard;
