import React, { useEffect, useState } from "react";
import DataSerices from "../../Services/DataSerices";
import config from "./config";
import { ContentCutOutlined } from "@mui/icons-material";

const Alert = (props) => {
  const [alertData, setAlertData] = useState([]);
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([""]);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [orgNameInfo, setOrgNameInfo] = useState("");
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const { selectedChannel, selectedDates } = config.state;

  let postData;

  useEffect(() => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    let userOrg = null;
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      var dummy = [];
      parsedUser.organization.map((item, index) => {
        dummy.push({ value: item.orgCode, label: item.orgName });
      });
      userOrg = dummy[0].value;
      setOrgNameInfo(userOrg);
    }
    postData = {
      channel: selectedChannel,
      org: userOrg,
      startDate: selectedDates.startDate,
      endDate: selectedDates.endDate,
    };
    getAlertDetails(postData);
  }, [config.state.selectedChannel]);

  const getAlertDetails = async (postData) => {
    try {
      const response = await DataSerices.getEvents(postData);

      if (response && response.data && response.data.response.body) {
        const menuItems = response.data.response.body.map((item) => ({
          item: item.items,
        }));
        setAlertData(menuItems);
        config.setAlertData(menuItems);
      } else {
        setError("API response does not contain expected data.");
      }
    } catch (error) {
      console.error("Error fetching alert data:", error);
      setError("Error fetching data from the API.");
    }
  };

  const handleButtonClick = (item) => {
    if (selectedAlerts.includes(item)) {
      setSelectedAlerts((prevSelectedAlerts) =>
        prevSelectedAlerts.filter((selectedEvent) => selectedEvent !== item)
      );
    } else {
      setSelectedAlerts((prevSelectedAlerts) => [...prevSelectedAlerts, item]);
    }
  };

  const buttonsMarkup = alertData.map((alert1) => (
    <button
      style={{
        padding: "5px 15px",
        margin: "5px",
        backgroundColor: selectedAlerts.includes(alert1.item)
          ? "#7CA3E2" // Selected color
          : "#D04848", // Default color
        color: "#fff",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      key={alert1.item}
      onClick={() => handleButtonClick(alert1.item)}
    >
      {alert1.item}
    </button>
  ));

  const handleOkClick = async () => {
    config.state.selectedAlerts = selectedAlerts;
    let data = {
      channel: config.state.selectedChannel,
      org: orgNameInfo,
      startDate: config?.state?.selectedDates?.startDate,
      endDate: config?.state?.selectedDates?.endDate,
      item: selectedAlerts,
    };

    try {
      const response = await DataSerices.getAlert(data);

      if (response && response.data && response.data.response.body.items) {
        const menuItem = response.data.response.body.items.map((item) => ({
          channel: item.channel,
          time: item.time,
        }));
        setSelectedEvents(menuItem);
        config.setSelectedEvents(menuItem);
        props.actions.eventOption();
        props.actions.continueOption();
      } else {
        setError("API response does not contain expected data.");
      }
    } catch (error) {
      console.error("Error fetching alert data:", error);
      setError("Error fetching data from the API.");
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!alertData) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      {buttonsMarkup}
      <button
        style={{
          padding: "6px 14px",
          margin: "5px",
          fontSize: "16px",
          backgroundColor: "#4CAF50",
          color: "#fff",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={() => {
          handleOkClick();

          setButtonsDisabled(true);
        }}
        disabled={buttonsDisabled}
      >
        Next
      </button>
    </div>
  );
};

export default Alert;
