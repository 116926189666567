import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import DataSerices from "../Services/DataSerices";

const NvrPopup = ({ onSave, open, handleClose, data, messageBack }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [host, setHost] = useState("");
  const [filepath, setFilepath] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [cameraNos, setCameraNos] = useState("");

  const handleSaveChanges = () => {
    const storedUserDetails = sessionStorage.getItem("userDetails");

    var userOrgCode = "";
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      var dummy = [];
      parsedUser.organization.map((item, index) => {
        dummy.push({ value: item.orgCode, label: item.orgName });
      });
      userOrgCode = dummy[0].value;
    }

    let data = {
      emailId: email,
      userName: userName,
      password: password,
      host: host,
      filepath: filepath,
      organization: userOrgCode,
      cameras: cameraNos,
    };
    updateDetails(data);
    onSave();
  };

  const updateDetails = async (data) => {
    await DataSerices.updateNvrCredentials(data)
      .then((res) => {
        let response = res?.data?.data;
        if (res.status == 200) {
          messageBack({ success: true });
        } else {
          messageBack({ success: false });
        }
      })
      .catch((e) => {
        console.log("error", e);
        let err =
          e &&
          e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.reason;
        let errValue = err ? err : "Something went wrong";
        messageBack({ success: false, errMsg: errValue });
      });
  };

  const handleModalClose = (e) => {
    setName("");
    setEmail("");
    setUserName("");
    setPassword("");
    setHost("");
    setFilepath("");
    setServiceName("");
    setCameraNos("");
    handleClose(e);
  };

  useEffect(() => {
    if (data && data.status == "Edit") {
      let d = data && data.data;
      setName(d.name ?? "");
      setCameraNos(d.cameras);
      setEmail(d && d.emailId ? d.emailId : "");
      if (d?.config) {
        setUserName(d.config[2].value ?? "");
        setPassword(d.config[1].value ?? "");
        setHost(d.config[3].value ?? "");
        setFilepath(d.config[0].value ?? "");
        setServiceName(d && d.serviceName ? d.serviceName : "");
      }
    }
  }, [data]);

  return (
    <Dialog open={open} onClose={handleModalClose}>
      <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={12} sm={12}>
            <TextField
              margin="normal"
              required
              value={name}
              id="name"
              label="Name"
              fullWidth
              name="name"
            />
          </Grid>

          <Grid item xs={12} md={12} sm={12}>
            <TextField
              margin="normal"
              required
              value={email}
              id="email"
              label="Email"
              fullWidth
              name="email"
            />
          </Grid>

          <Grid item xs={12} md={12} sm={12}>
            <TextField
              margin="normal"
              required
              value={userName}
              onChange={(event) => setUserName(event.target.value)}
              id="userName"
              label="FtpUserName"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={12} sm={12}>
            <TextField
              margin="normal"
              required
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              id="password"
              label="FtpPassword"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={12} sm={12}>
            <TextField
              margin="normal"
              required
              value={host}
              onChange={(event) => setHost(event.target.value)}
              id="host"
              label="Host"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <TextField
              margin="normal"
              required
              value={cameraNos}
              onChange={(event) => setCameraNos(event.target.value)}
              id="cameraNos"
              label="Camera Nos"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={12} sm={12}>
            <TextField
              margin="normal"
              required
              value={filepath}
              onChange={(event) => setFilepath(event.target.value)}
              id="filepath"
              label="FIlePath"
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSaveChanges}>
          Save
        </Button>
        <Button onClick={handleModalClose} variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NvrPopup;
