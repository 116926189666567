import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  memo,
} from "react";
import { Grid, Typography, Card, Button, Backdrop } from "@mui/material";
import AlertTableComponent from "./AlertTable";
import { useTheme } from "@mui/material";
import Log from "../../../Constants/log";
import DropDownContext from "../../Context/DropdowmContext";
import { Oval } from "react-loader-spinner";
import DataSerices from "../../../Services/DataSerices";
import alertJSON from "../../../Constants/Alert.json";
import { useDispatch, useSelector } from "react-redux";
import appConstants from "../../../Constants/AppConst";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AlertStreaming = ({ dropData }) => {
  const dispatch = useDispatch();
  const {
    alertData,
    imageURL,
    selectedIndex,
    issueTotalCount,
    resolvedTotalCount,
  } = useSelector((state) => state.CustomFunctionsModal);

  const theme = useTheme();
  const styles = {
    container: {
      width: "100%",
      height: `55vh`,
      // overflow: 'auto',
    },
    button1: {
      width: "100%",
      backgroundColor: theme.palette.primary.dark,
      textAlign: `center`,
      padding: `20px`,
    },
    button2: {
      width: "100%",
      backgroundColor: theme.palette.success.dark,
      textAlign: `center`,
      padding: `20px`,
    },
    button3: {
      width: "100%",
      backgroundColor: theme.palette.success.dark,
      textAlign: `center`,
      padding: `20px`,
    },
  };
  // const [list, setList] = useState([]);
  const condoName = useContext(DropDownContext);
  const [loading, setLoading] = useState(false);
  const [backdropLoad, setBackdropLoad] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedText, setSelectedText] = useState("");

  useEffect(() => {
    try {
      if (alertData && alertData.alerts && alertData.alerts.length < 1) {
        if (dropData.length > 0 && dropData != "condo") {
          IntialLoadingData();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const apiRender = setInterval(() => {
      if (dropData.length > 0) {
        IntialLoadingData();
      }
    }, 300000);
    return () => clearInterval(apiRender);
  }, []);

  const IntialLoadingData = async () => {
    try {
      setLoading(true);
      await dispatch.CustomFunctionsModal.handleInitialRendering(dropData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const ToastMessage = (message, info) => {
    switch (message) {
      case "success":
        toast.success(info, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        break;
      case "error":
        toast.error(info, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        break;
      default:
        return null;
    }
  };

  const onSelect = (e) => {};

  const submitRaiseCase = async () => {
    try {
      setBackdropLoad(true);
      let data = {
        id: alertData?.alerts[selectedIndex]?.id,
        status: appConstants.ISSUERAISED,
      };
      await DataSerices.handleRaiseOrResolveCase(data)
        .then((res) => {
          IntialLoadingData();
          ToastMessage("success", "Details updated");
        })
        .catch((err) => {
          console.log(err);
          ToastMessage("error", "Something went wrong");
        });
    } catch (error) {
      console.log(error);
    } finally {
      setBackdropLoad(false);
      setOpen(false);
    }
  };

  const submitMarkAsResolved = async () => {
    try {
      setBackdropLoad(true);
      let data = {
        id: alertData?.alerts[selectedIndex]?.id,
        status: appConstants.RESOLVED,
      };
      await DataSerices.handleRaiseOrResolveCase(data)
        .then((res) => {
          IntialLoadingData();
          ToastMessage("success", "Details updated");
        })
        .catch((err) => {
          console.log(err);
          ToastMessage("error", "Something went wrong");
        });
    } catch (error) {
      console.log(error);
    } finally {
      setBackdropLoad(false);
      setOpen(false);
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "65vh",
          }}
        >
          <Oval
            height={80}
            width={80}
            color={theme.palette.primary.dark}
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor={theme.palette.secondary.dark}
            strokeWidth={1}
            strokeWidthSecondary={1}
          />
        </div>
      ) : (
        <Grid
          container
          spacing={4}
          style={{ padding: `0px`, marginTop: `-50px` }}
        >
          <Grid item xs={12} sm={6} md={6}>
            {/* <ReactPlayer
                    url={videoLink.AlertVideo}
                    playing
                    controls
                    width="100%"
                    height="55vh"                 
                /> */}
            {imageURL ? (
              <img src={imageURL} style={styles.container} alt="Alert" />
            ) : (
              <Typography
                variant="h6"
                align="center"
                style={{ padding: "20px" }}
              >
                No Image to Display
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <AlertTableComponent
              list={alertData.alerts}
              onSelectData={onSelect}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ padding: `0px`, marginTop: `10px` }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  style={styles.button1}
                  onClick={() => {
                    setSelectedText(appConstants.ISSUERAISED);
                    setOpen(true);
                  }}
                >
                  Raise Case
                </Button>
              </Grid>

              <Grid item xs={3}>
                <Button
                  variant="contained"
                  style={styles.button2}
                  onClick={() => {
                    setSelectedText(appConstants.RESOLVED);
                    setOpen(true);
                  }}
                >
                  {" "}
                  Mark as Resolved
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              sx={{ marginTop: "1%" }}
            >
              <Grid item xs={3}>
                <Typography align="center">
                  Issue raised count: {issueTotalCount}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="center">
                  Issue resolved count: {resolvedTotalCount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropLoad}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <ToastContainer />
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to{" "}
                {selectedText == appConstants.RESOLVED
                  ? "resolve this alert?"
                  : "raise this alert?"}
                .
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setOpen(false)}
                autoFocus
              >
                Disagree
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (selectedText === appConstants.ISSUERAISED) {
                    submitRaiseCase();
                  } else if (selectedText === appConstants.RESOLVED) {
                    submitMarkAsResolved();
                  }
                }}
              >
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </>
  );
};

export default memo(AlertStreaming);
