import React, { useState, useEffect, useCallback } from "react";
import { Button, TextField, Typography, Grid, Box, Paper } from "@mui/material";
import DataSerices from "../Services/DataSerices";
import EnvironmentalSetUp from "./EnvironmentalSetup";
import BackDropLoading from "../Components/Loading";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

const CameraSetup = () => {
  const [cameraCount, setCameraCount] = useState("");
  const [email, setEmail] = useState("");
  const [ftpCredentials, setFtpCredentials] = useState([]);
  const [loaderSetup, setLoaderSetup] = useState(false);
  const [saveFun, setSaveFun] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [loding, setloading] = useState(false);
  const [stagesAPI, setStagesAPI] = useState(0);

  const steps = [
    {
      label: "Camera Setup",
      type: "input",
    },
    {
      label: "FTP Credentials",
      type: "table",
    },
    {
      label: "Environmental Setup",
      type: "muiTable",
    },
  ];

  const handleCameraCountChange = (event) => {
    setCameraCount(event.target.value);
  };

  const registerNvrDetails = async () => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    var userorg = "";
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      var dummy = [];
      parsedUser.organization.map((item, index) => {
        dummy.push({ value: item.orgCode, label: item.orgName });
      });
      userorg = dummy[0].value;
    }
    let data = {
      emailId: email,
      cameras: cameraCount,
      stage: 1,
      organization: userorg,
    };
    await DataSerices.registerNvr(data)
      .then((response) => {
        let stage = response.data.response.body.stage;
        if (stage && stage === 1) {
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateNvrDetails = async () => {
    let data = {
      emailId: email,
      stage: 2,
    };
    await DataSerices.registerNvr(data)
      .then((response) => {
        let stage = response.data.response.body.stage;
        if (stage && stage === 2) {
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getNvrDetails = async (email) => {
    try {
      const response = await DataSerices.getNvrDetails(email);
      if (response.data.response.body.stage) {
        setActiveStep(parseInt(response.data.response.body.stage));
        setStagesAPI(parseInt(response.data.response.body.stage));
      }

      if (response.data.response.body.config) {
        var tableVal = [];
        var dummy = {
          ftpPassword: "",
          filePath: "",
          host: "",
          ftpUserName: "",
        };
        response.data.response.body.config.map((item) => {
          if (item.name == "ftpPassword") {
            dummy.ftpPassword = item.value;
          } else if (item.name == "filePath") {
            dummy.filePath = item.value;
          } else if (item.name == "host") {
            dummy.host = item.value;
          } else if (item.name == "ftpUserName") {
            dummy.ftpUserName = item.value;
          }
        });
        tableVal.push(dummy);
        setFtpCredentials(response.data.response.body.config);
      } else {
        console.log("exit");
      }

      setCameraCount(response.data.response.body.cameras);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setloading(true);
  }, []);

  useEffect(() => {
    if (loding && email.length == 0) {
      const storedUserDetails = sessionStorage.getItem("userDetails");
      const parsedUser = JSON.parse(storedUserDetails);
      setEmail(parsedUser.emailId);
      getNvrDetails(parsedUser.emailId);
    }
  }, [email, loding]);

  const handleLoader = useCallback(
    (e) => {
      setLoaderSetup(e);
    },
    [loaderSetup]
  );

  useEffect(() => {
    if (activeStep === 1) {
      if (cameraCount.length > 0 && ftpCredentials.length == 0) {
        registerNvrDetails();
      }
    } else if (activeStep === 2 && stagesAPI !== 2) {
      updateNvrDetails();
    }
  }, [activeStep]);

  const handleNexts = (index) => {
    if (index == 2) {
      setSaveFun(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBacks = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item sm={10} xs={10} md={10}>
        {loaderSetup && <BackDropLoading open={loaderSetup} />}
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>
                <Typography variant="subtitle1">{step.label}</Typography>
              </StepLabel>
              <StepContent>
                {step.type == "input" && (
                  <Grid item sm={9} xs={9} md={9}>
                    <TextField
                      label="Number of Cameras"
                      type="number"
                      value={cameraCount}
                      onChange={handleCameraCountChange}
                      style={{
                        marginBottom: "10px",
                        marginTop: `1rem`,
                        width: "50%",
                      }}
                    />
                  </Grid>
                )}
                {step.type == "table" && (
                  <Grid item sm={12} xs={7} md={7}>
                    {ftpCredentials.length > 0 ? (
                      <Paper
                        elevation={3}
                        sx={{
                          minHeight: "200px",
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: `1rem`,
                          width: "100%",
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          display={"flex"}
                          justifyContent={"center"}
                          sx={{ width: "100%" }}
                        >
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            style={{
                              background: "#4B5563",
                              minHeight: "200px",
                              borderRadius: "1rem",
                              width: "100%",
                            }}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"space-evenly"}
                          >
                            {ftpCredentials.map((item, indexss) => {
                              return (
                                <Grid
                                  container
                                  display={"flex"}
                                  justifyContent={"space-evenly"}
                                  sx={{
                                    width: "100%",
                                  }}
                                  key={indexss}
                                >
                                  <Grid item sm={5} xs={5} md={5}>
                                    <Typography
                                      style={{
                                        fontVariant: "all-petite-caps",
                                        marginLeft: `1rem`,
                                        width: "100%",
                                      }}
                                    >
                                      {item.name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    sm={6}
                                    xs={6}
                                    md={6}
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      style={{
                                        marginLeft: `.5rem`,
                                        width: "80%",
                                      }}
                                    >
                                      {item.value}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Paper>
                    ) : (
                      <Paper
                        elevation={3}
                        sx={{
                          height: "200px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "center" }}
                        >
                          Your Credentials is being generated
                        </Typography>
                      </Paper>
                    )}
                  </Grid>
                )}
                {step.type == "muiTable" && (
                  <EnvironmentalSetUp
                    loader={(e) => handleLoader(e)}
                    ifchild={true}
                    saveFun={saveFun}
                    nodataFound={(e) => {
                      toast.error(e);
                      setSaveFun(false);
                    }}
                    messageBack={(e) => {
                      if (e) {
                        toast.success("Data updated sucessfully");
                        setSaveFun(false);
                      } else {
                        toast.error("Something went wrong");
                        setSaveFun(false);
                      }
                    }}
                  />
                )}
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={(e) => handleNexts(index)}
                      sx={{ mt: 1, mr: 1 }}
                      style={{
                        marginTop: `2rem`,
                      }}
                      disabled={index === 1 && ftpCredentials.length == 0}
                    >
                      {index === steps.length - 1
                        ? "Save"
                        : index === 0 || index === 1
                        ? "Continue"
                        : "Save"}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBacks}
                      sx={{ mt: 1, mr: 1 }}
                      style={{
                        marginTop: `2rem`,
                      }}
                    >
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default CameraSetup;
