import React from 'react';


const MessageParser = ({ children, actions }) => {

    const parse = (message) => {
        if (message.includes('no')){
            return;
        }
      if (message.includes('')) {
        actions.dateOption();
    } 
   
    }
    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions,
                });
            })}
        </div>
    );
};

export default MessageParser;
