import React, { useState } from "react";
import config from "./Chatbot/config";

const ChannelButton = (props) => {
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  

  return (
    <div >
      <button style={button} onClick={() =>  {props.actions.cameraOption();
         setButtonsDisabled(true);
        }}
         disabled={buttonsDisabled}
         >
        Yes
      </button>
      <button style={button}
        onClick={() => {
          props.actions.alertOption();
          setButtonsDisabled(true);
          config.state.selectedChannel = ("");
          config.state.alertData = "";
          config.state.selectedAlerts = "";
          config.state.selectedItems = "";
        
        }}
        disabled={buttonsDisabled}
      >
        NO
      </button>
    </div>
  );
};
const button = {
  padding: "5px 15px",
        margin: "5px",
        backgroundColor: "#D04848",
        color: "#fff",
        borderRadius: "5px",
        cursor: "pointer",
};

export default ChannelButton;

