// ActionProvider Component

import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  

  const buttonOption = () =>{
    const message = createChatBotMessage("Do you want to select the channels?",{
    widget: "button"
    });
    updateState(message);
  };

  const cameraOption = () => {
    const message = createChatBotMessage("Select the channels", {
      widget: "option"
    });
    updateState(message);
  };

  const alertOption = () => {
      updateState(  createChatBotMessage(`Select the object type`, {
          widget: "alert",
          
        }));
     
  };
  const eventOption = () => {
    updateState(createChatBotMessage("Object Details", { widget: "event" }));
  };
  
  const dateOption = () => {
    updateState(createChatBotMessage("Select the date", { widget: "date" }));
  };


  const continueOption = () => {
    updateState(createChatBotMessage("Do you want to continue?", {widget: "continue"}));
  };

  const closeOption = () => {
    setState((prev) => ({
      
     
    }));
  };

  const updateState = (message, checker, selectedChannel) => {
    console.log("message...===?",message)
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      checker,
     
    }));
  };
 
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            cameraOption,
            alertOption,
            dateOption,
            continueOption,
            buttonOption,eventOption,closeOption
          },
       
        });
      })}
    </div>
  );
};

export default ActionProvider;
