import React, { useEffect, memo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Typography } from "@mui/material";

const styles = {
  container: {
    height: `55vh`, //getInfo == null ? `80vh`: '50vh',
    width: "100%",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },

  noDataText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
};

function AlertTableComponent({ list }) {
  const dispatch = useDispatch();
  const { selectedIndex } = useSelector((state) => state.CustomFunctionsModal);

  const handleClick = (e, index) => {
    dispatch.CustomFunctionsModal.setSelectedIndex(index);
    dispatch.CustomFunctionsModal.handleImageData(e.imageUrl);
  };

  useEffect(() => {
    if (list?.length > 0) {
      dispatch.CustomFunctionsModal.handleImageData(list[0].imageUrl);
    }
  }, [list]);

  return (
    // <Paper sx={{ width: "100%", overflow: "hidden" }}>
    //   <TableContainer sx={styles.container}>
    //     <Table stickyHeader aria-label="sticky table">
    //       <TableHead>
    //         <TableRow>
    //           <TableCell>Date & Time</TableCell>
    //           <TableCell>Alert Type</TableCell>
    //           <TableCell>Channel</TableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //       {list.length > 0 ? (
    //           list.map((values, index) => {

    //               <TableRow
    //                 key={index}
    //                 hover
    //                 role="checkbox"
    //                 style={{
    //                   background: selectedIndex === index ? "red" : null,
    //                 }}
    //                 onClick={() => handleClick(values, index)}
    //               >
    //                 <TableCell>
    //                   {moment
    //                     .utc(values.createdDateTime)
    //                     .local()
    //                     .format("YYYY-MM-DD HH:mm:ss")}
    //                 </TableCell>
    //                 <TableCell>{values.alertType}</TableCell>
    //                 <TableCell>{values.channel}</TableCell>
    //               </TableRow>

    //           } ) : (
    //             <TableRow>
    //               <TableCell colSpan={3}>
    //                 <Typography sx={styles.noDataText}>
    //                   No data found
    //                 </Typography>
    //               </TableCell>
    //             </TableRow>
    //           )}
    //       </TableBody>
    //     </Table>
    //   </TableContainer>
    // </Paper>

    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={styles.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Date & Time</TableCell>
              <TableCell>Alert Type</TableCell>
              <TableCell>Channel</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length > 0 ? (
              list.map((values, index) => (
                <TableRow
                  key={index}
                  hover
                  role="checkbox"
                  style={{
                    background: selectedIndex === index ? "red" : null,
                  }}
                  onClick={() => handleClick(values, index)}
                >
                  <TableCell>
                    {moment
                      .utc(values.createdDateTime)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                  <TableCell>{values.alertType}</TableCell>
                  <TableCell>{values.channel}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography sx={styles.noDataText}>No data found</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default memo(AlertTableComponent);
