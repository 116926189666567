import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import colorConfigs from "../configs/colorConfigs";
import { Card, CardContent } from "@mui/material";
import Logo from "../Assets/Logo.png";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackDropLoading from "../Components/Loading";
import { ForgetPasswordValidateForm } from "./FormValidation";
import AuthService from "../Services/AuthService";

let validation = {
  email: false,
};

let validationMsg = {
  email: "",
};

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [valid, setValid] = useState(validation);
  const [msg, setMsg] = useState(validationMsg);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValid(validation);
    setMsg(validationMsg);
  }, [email]);

  const handleForgotPassword = () => {
    setIsLoading(true);
    let data = {
      email: email,
    };
    let err = ForgetPasswordValidateForm(data);
    if (err == null) {
      onRecoverPassword(data);
    } else {
      setIsLoading(false);
      if (err && err.email) {
        setMsg((prevState) => ({
          ...prevState,
          email: err.email,
        }));
        setValid((prevState) => ({
          ...prevState,
          email: true,
        }));
      }
    }
  };

  const onRecoverPassword = async (data) => {
    await AuthService.onRecover(data)
      .then((response) => {
        toast.success("Please check your email");
        setIsLoading(false);
        navigate("/login");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: colorConfigs.mainBg, minHeight: "100vh" }}
    >
      <Card sx={{ maxWidth: 500, minWidth: 400 }}>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <img src={Logo} width={`60%`} height={40} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              component="h1"
              variant="body2"
              style={{ textAlign: `center` }}
            >
              Forgot your Password?
            </Typography>
          </Grid>
          <form>
            <Grid container spacing={2} style={{ padding: `10px` }}>
              <Grid item xs={12} md={12} sm={12}>
                <TextField
                  required
                  id="outlined-required"
                  label="Email"
                  error={valid.email}
                  onChange={(e) => setEmail(e.target.value)}
                  helperText={valid.email ? msg.email : ""}
                  fullWidth
                />
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12} md={12} sm={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={() => handleForgotPassword()}
            >
              Recover Password
            </Button>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: `10px` }}
            spacing={2}
          >
            <Grid item xs={4} sm={3} md={3}>
              <Link onClick={() => navigate("/login")} variant="body2">
                {"Sign In"}
              </Link>
            </Grid>
            <Grid item xs={8} sm={9} md={9}>
              <Link
                onClick={() => navigate("/signup")}
                variant="body2"
                style={{ float: "right" }}
              >
                {"Sign Up"}
              </Link>
            </Grid>
          </Grid>
          {isLoading && <BackDropLoading open={isLoading} />}
          <ToastContainer />
        </CardContent>
      </Card>
    </Grid>
  );
}
