import React, { useState, useEffect } from "react";
import { Avatar, Drawer, List, Stack, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../Routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarCollapse";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../Assets/Logo.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";

const Sidebar = (props) => {
  const [allow, setAllow] = useState([]);

  useEffect(() => {
    const storedUserDetails = sessionStorage.getItem("userDetails");

    let userRole = null;

    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);

      userRole = parsedUser.roleName; // Accessing the role field from your stored data
    }
    //let userRole = 'USER'; // add the user role from storedUserDetails
    let allowedUser = [];

    const checkAccess = (allowedRoles) => {
      return allowedRoles.includes(userRole);
    };
    appRoutes.map((route, index) => {
      if (checkAccess(route?.allowedRoles)) {
        allowedUser.push(route);
      }
    });
    setAllow(allowedUser);
  }, []);

  const theme = useTheme();
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: props.open
          ? sizeConfigs.sidebar.width
          : sizeConfigs.sidebar.xsWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: props.open
            ? sizeConfigs.sidebar.width
            : sizeConfigs.sidebar.xsWidth,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
        },
      }}
    >
      <List
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          margin: 0,
          padding: 0,
          listStyle: "none",
          height: "100%",
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            outline: "1px solid slategrey",
          },
        }}
      >
        <Toolbar sx={{ marginBottom: "20px" }}>
          {props && props.open && (
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center"
            >
              <IconButton
                sx={{ color: colorConfigs.sidebar.color }}
                onClick={props.changeEvent}
              >
                {/*  <KeyboardArrowRightIcon  style={{marginRight: `-200px`}}/>  */}
                <img src={logo} width={180} style={{ padding: `10px` }} />
                {/*  <MenuIcon /> */}
              </IconButton>
            </Stack>
          )}
          {props && !props.open && (
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center"
            >
              <IconButton
                sx={{ color: colorConfigs.sidebar.color }}
                onClick={props.changeEvent}
              >
                {/*  <img src={logo} width={180} height={50} /> */}
              </IconButton>
            </Stack>
          )}
        </Toolbar>
        {allow.length > 0 &&
          allow.map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          )}
      </List>
    </Drawer>
  );
};

export default Sidebar;
