import httpChat from "./http-chat";
import http_common from "./http-common";
import httpCommonWegrow from "./http-commonWegrow";
import httpCommoncommoncs360 from "./http-commoncs360";

import { auth } from "./Firebase";

/* /api/user/role/{role}/status/{status} */
/* /api/user/status */

const authHeader = () => {
  let headerDetails = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
    },
  };
  return headerDetails;
};

const getUserList = (data) => {
  return http_common.get(`/user/role/${data.role}/status/${data.status}`);
};

const updateUser = (data) => {
  return http_common.put(`/user/status`, data);
};

const updateUserDetails = (data) => {
  return http_common.put(`/user`, data);
};

const registerAccessCard = (data) => {
  return http_common.post(`/condo/accessCard/registration`, data);
};

const editAccessCard = (data) => {
  return http_common.post(`/condo/accessCard/edit`, data);
};

const getAccessList = (data) => {
  return http_common.get(`/condo/accessCard/list?status=${data.status}`);
};

const deleteAccessCard = (data) => {
  return http_common.post(`/condo/accessCard/delete`, data);
};

const addAccessCard = (data) => {
  return http_common.post(`/condo/accessCard/add`, data);
};

const getAccessLogs = (data) => {
  return http_common.get(
    `/condo/accessCard/logs?pageNo=${data.page}&pageSize=${data.size}&type=${data.type}`
  );
};

const getVehicleAuditReport = (data) => {
  return http_common.get(
    `/visitorInfo/audit/data?overstay=${data.overstay}&type=${data.type}&pageNo=${data.pageNo}&pageSize=${data.pageSize}&vehicleNo=${data.filterData.vehicleNo}&visitorType=${data.filterData.visitorType}`
  );
};

const searchEntryExit = (data) => {
  let x = data.field;
  let a =
    x.category == `All`
      ? ""
      : x.category.length > 0
      ? `&category=${data.field.category}`
      : "";
  let b = x.name.length > 0 ? `&name=${data.field.name}` : "";
  let c = x.block.length > 0 ? `&blockNo=${data.field.block}` : "";
  let d = x.vehicleNo.length > 0 ? `&vehicleNos=${data.field.vehicleNo}` : "";
  let e = x.contactNo.length > 0 ? `&mobileNo=${data.field.contactNo}` : "";
  let k = x.monthWise.length > 0 ? `&monthYear=${data.field.monthWise}` : "";
  let f = a + b + c + d + e + k;
  return http_common.get(
    `/vehicle/search/entryexit?pageNo=${data.pageNo}&pageSize=${data.pageSize}${f}`
  );
};

const downloadEntryExitExcel = (data) => {
  let x = data && data.field && data.field;
  let b = x && x.name && x.name.length > 0 ? `&name=${data.field.name}` : "";
  let c =
    x && x.block && x.block.length > 0 ? `&blockNo=${data.field.block}` : "";
  let d =
    x && x.vehicleNo && x.vehicleNo.length > 0
      ? `&vehicleNos=${data.field.vehicleNo}`
      : "";
  let e =
    x && x.contactNo && x.contactNo.length > 0
      ? `&mobileNo=${data.field.contactNo}`
      : "";
  let k =
    x && x.monthWise && x.monthWise.length > 0
      ? `&monthYear=${data.field.monthWise}`
      : "";
  let f = b + c + d + e + k;
  let url;
  if (data) {
    url = `/vehicle/search/entryexit/csv?${f}`;
  } else {
    url = `/vehicle/search/entryexit/csv`;
  }
  return http_common.get(url);
};

const onRaiseCase = (data) => {
  return http_common.post(`/condo/accessCard/add`, data);
};

const onMarkAsResolved = (data) => {
  return http_common.post(`/condo/accessCard/add`, data);
};

const getAlertsDetails = (data) => {
  let z = `alerts?page=${data.page}&size=${data.size}`;
  let x = data?.alertType ? `&alertType=${data.alertType}` : "";
  let y = data?.channel ? `&channel=${data.channel}` : "";
  let w = data?.fromDate ? `&fromDate=${data.fromDate}` : "";
  let v = data?.toDate ? `&toDate=${data.toDate}` : "";
  let u = `&orgName=${data.orgName}`;
  let t = data?.statusVal ? `&status=${data.statusVal}` : "";
  let url = z + x + y + w + v + u + t;
  return httpCommonWegrow.get(url, authHeader());
};
const handleRaiseOrResolveCase = (data) => {
  return httpCommonWegrow.put(`alerts`, data, authHeader());
};

const getChannelList = (data) => {
  return httpCommonWegrow.get(
    `alerts/channels?orgName=${data}`,

    authHeader()
  );
};

const handlePatrolAlert = (data) => {
  return httpCommonWegrow.put(`alerts`, data, authHeader());
};

const getNvrDetails = (data) => {
  return httpCommonWegrow.get(`/nvr/details?emailId=${data}`, authHeader());
};

const getAllNvrDetails = (data) => {
  return httpCommonWegrow.get(
    `/nvr/approval/details?emailId=${data}`,
    authHeader()
  );
};

const registerNvr = (data) => {
  return httpCommonWegrow.post(`/nvr/create`, data, authHeader());
};

const updateNvrCredentials = (data) => {
  return httpCommonWegrow.post(`/nvr/credentials`, data, authHeader());
};
const environmentalSetup = (data, orgName) => {
  return httpCommonWegrow.get(
    `/nvr/environment/setup?emailId=${data}&orgName=${orgName}`,
    authHeader()
  );
};

const handleEnvSetup = async (data) => {
  return await httpCommonWegrow.post(
    `/nvr/environment/setup`,
    data,
    authHeader()
  );
};

const getChannel = (data) => {
  return httpCommonWegrow.get(`nvr/channels?emailId=${data}`, authHeader());
};

const getDashboardURL = (data) => {
  return httpCommonWegrow.get(`nvr/kpi/url?orgName=${data}`, authHeader());
};

const getEvents = (data) => {
  let u = `nvr/items?orgName=${data.org}`;
  let v = data?.startDate ? `&startDate=${data.startDate}` : "";
  let w = data?.endDate ? `&endDate=${data.endDate}` : "";
  let x = data?.channel ? `&channel=${data.channel}` : "";
  let url = u + v + w + x;
  return httpCommonWegrow.get(url, authHeader());
};

const getAlert = (data) => {
  let u = `nvr/items/details?orgName=${data.org}`;
  let v = data?.startDate ? `&fromDate=${data.startDate}` : "";
  let w = data?.endDate ? `&toDate=${data.endDate}` : "";
  let x = data?.channel ? `&channel=${data.channel}` : "";
  let y = data?.item ? `&items=${data.item}` : "";
  let url = u + v + w + x + y;
  return httpCommonWegrow.get(url, authHeader());
};

const getSecurityDatas = (data) => {
  let url = `visitorInfo/report/${data}`;
  return httpCommoncommoncs360.get(url, authHeader());
};

const getSecurityTableDatas = (data) => {
  let url = `/visitorInfo/overStay/records/${data}`;
  return httpCommoncommoncs360.get(url, authHeader());
};

const searchEntryExitSecurity = (data) => {
  let x = data.field;
  let a =
    x.category == `All`
      ? ""
      : x.category.length > 0
      ? `&category=${data.field.category}`
      : "";
  let b = x.name.length > 0 ? `&name=${data.field.name}` : "";
  let c = x.block.length > 0 ? `&blockNo=${data.field.block}` : "";
  let d = x.vehicleNo.length > 0 ? `&vehicleNos=${data.field.vehicleNo}` : "";
  let e = x.contactNo.length > 0 ? `&mobileNo=${data.field.contactNo}` : "";
  let k = x.monthWise.length > 0 ? `&monthYear=${data.field.monthWise}` : "";
  let f = a + b + c + d + e + k;
  return httpCommoncommoncs360.get(
    `visitorInfo/entryexitlogs/${data.org}?pageNo=${data.pageNo}&pageSize=${data.pageSize}${f}`,
    authHeader()
  );
};

export default {
  getEvents,
  getUserList,
  updateUser,
  registerAccessCard,
  getAccessList,
  deleteAccessCard,
  addAccessCard,
  editAccessCard,
  getAccessLogs,
  updateUserDetails,
  getVehicleAuditReport,
  searchEntryExit,
  downloadEntryExitExcel,
  onRaiseCase,
  onMarkAsResolved,
  getAlertsDetails,
  handleRaiseOrResolveCase,
  getChannelList,
  handlePatrolAlert,
  getNvrDetails,
  registerNvr,
  getAllNvrDetails,
  updateNvrCredentials,
  environmentalSetup,
  handleEnvSetup,
  getChannel,
  getAlert,
  getDashboardURL,
  getSecurityDatas,
  getSecurityTableDatas,
  searchEntryExitSecurity,
};
