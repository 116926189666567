import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import StreamIcon from "@mui/icons-material/Stream";
import MapIcon from "@mui/icons-material/Map";
import AddCardIcon from "@mui/icons-material/AddCard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import Dashboard from "../Pages/Dashboard";
import AccessCardRegistration from "../Pages/AccessCardRegistration";
import Map from "../Pages/Maps/index";
import LiveFeedsDashboard from "../Pages/LiveFeed";
import VisitorRegistration from "../Pages/VisitorRegistration/VisitorRegistration";
import DatabaseStreaming from "../Pages/Database/Database";
import AccessCardLog from "../Pages/AccessCardLog";
import VehicleReport from "../Pages/VehicleReport";
import EntryExit from "../Pages/EntryExit";
import ReportLayout from "./ReportLayout";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AlertMonitoringReport from "../Pages/AlertMonitoringReport";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import NvrApproval from "../Pages/NvrApproval";
import NvrDetails from "../Pages/NvrDetails";
import CameraSetup from "../Pages/CameraSetup";
import EnvironmentalSetUp from "../Pages/EnvironmentalSetup";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import SecurityDashboard from "../Pages/SecurityDashboard/SecurityDashboard";

const appRoutes = [
  /* {
        index: true,
        element: <Map />,
        state: "Map"
    }, */
  {
    path: "/dashboard",
    element: <Dashboard />,
    state: "UserApproval",
    sidebarProps: {
      displayText: "User Approval",
      icon: <DashboardOutlinedIcon />,
    },
    allowedRoles: ["ADMIN", "MANAGER", "SUPERADMIN"],
  },
  {
    path: "/security",
    element: <SecurityDashboard />,
    state: "SecurityDashboard",
    sidebarProps: {
      displayText: "Security Dashboard",
      icon: <DashboardOutlinedIcon />,
    },
    allowedRoles: ["SECURITY"],
  },

  {
    path: "/smart-patrol",
    element: <LiveFeedsDashboard />,
    state: "SmartPatrol",
    sidebarProps: {
      displayText: "Smart Patrol",
      icon: <StreamIcon />,
    },
    allowedRoles: ["ADMIN", "MANAGER", "SUPERADMIN", "VIEWADMIN"],
  },

  /* {
      path: "/database",
      element: <DatabaseStreaming />,
      state: "Database",
      sidebarProps: {
        displayText: "Database",
        icon: <StorageOutlinedIcon />
      }
    }, 
    
    {
        path: "/user-approval", //"/user-approval",
        element: <Dashboard />,
        state: "UserApproval",
        sidebarProps: {
          displayText: "User Approval",
          icon: <DashboardOutlinedIcon />
        }
    }, */

  {
    path: "/access-card-registration",
    element: <AccessCardRegistration />,
    state: "AccessCardRegistration",
    sidebarProps: {
      displayText: "Access Card Registration",
      icon: <AddCardIcon />,
    },
    allowedRoles: ["ADMIN", "MANAGER", "SUPERADMIN"],
  },

  /* {
      path: "/access-card-logs",
      element: <AccessCardLog />,
      state: "AccessCardLog",
      sidebarProps: {
        displayText: "Access Card Logs",
        icon: <AddCardIcon />
      }
    },
    {
      path: "/vehicle-report",
      element: <EntryExit />,
      state: "VehicleReport",
      sidebarProps: {
        displayText: "Vehicle Audit Report",
        icon: <AddCardIcon />
      }
    }, */
  {
    path: "/reports/alert-report",
    element: <AlertMonitoringReport />,
    state: "AlertMonitoringReport",
    sidebarProps: {
      displayText: "Alert Monitoring Report",
      icon: <AddAlertIcon />,
    },
    allowedRoles: ["VIEWADMIN"],
  },
  {
    path: "/reports/vehicle-report",
    element: <EntryExit />,
    state: "VehicleReport",
    sidebarProps: {
      displayText: "Vehicle Audit Report",
      icon: <DirectionsCarIcon />,
    },
    allowedRoles: ["SECURITY"],
  },
  {
    path: "/reports",
    state: "Reports",
    element: <ReportLayout />,
    sidebarProps: {
      displayText: "Reports",
      icon: <SummarizeIcon />,
    },
    allowedRoles: ["ADMIN", "MANAGER", "SUPERADMIN"],
    child: [
      {
        path: "/reports/access-card-logs",
        element: <AccessCardLog />,
        state: "AccessCardLog",
        sidebarProps: {
          displayText: "Access Card Logs",
          icon: <AddCardIcon />,
        },
        allowedRoles: ["ADMIN", "MANAGER", "SUPERADMIN"],
      },

      {
        path: "/reports/vehicle-report",
        element: <EntryExit />,
        state: "VehicleReport",
        sidebarProps: {
          displayText: "Vehicle Audit Report",
          icon: <DirectionsCarIcon />,
        },
        allowedRoles: ["ADMIN", "MANAGER", "SUPERADMIN"],
      },

      {
        path: "/reports/alert-report",
        element: <AlertMonitoringReport />,
        state: "AlertMonitoringReport",
        sidebarProps: {
          displayText: "Alert Monitoring Report",
          icon: <AddAlertIcon />,
        },
        allowedRoles: ["ADMIN", "MANAGER", "SUPERADMIN"],
      },
    ],
  },
  /* {
      path: "/visitor-registration",
      element: <VisitorRegistration />,
      state: "VisitorRegistration",
      sidebarProps: {
        displayText: "Visitor Registration",
        icon: <PersonAddIcon />
      }
    }, */
  {
    path: "/nvrApproval",
    element: <NvrApproval />,
    state: "NvrApproval",
    sidebarProps: {
      displayText: "Nvr Approval",
      icon: <StreamIcon />,
    },
    allowedRoles: ["SUPERADMIN"],
  },

  // {
  //   path: "/npr-Details",
  //   element: <NvrDetails />,
  //   state: "NvrDetails",
  //   sidebarProps: {
  //     displayText: "Nvr Details",
  //     icon: <StreamIcon />
  //   },
  //   allowedRoles:['MANAGER']
  // },
  {
    path: "/camera-setup",
    element: <CameraSetup />,
    state: "CameraSetup",
    sidebarProps: {
      displayText: "Camera Setup",
      icon: <StreamIcon />,
    },
    allowedRoles: ["MANAGER", "ADMIN"],
  },
  // {
  //   path: "/environment",
  //   element: <EnvironmentalSetUp />,
  //   state: "EnvironmentalSetUp",
  //   allowedRoles:['MANAGER']
  // },
  {
    path: "/environmentalSetUp",
    element: <EnvironmentalSetUp />,
    state: "EnvironmentalSetUp",
    sidebarProps: {
      displayText: "Environmental SetUp",
      icon: <TaskAltIcon />,
    },
    allowedRoles: ["MANAGER", "ADMIN", "SUPERADMIN", "VIEWADMIN"],
  },
];

export default appRoutes;
