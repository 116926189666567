
import React, { useContext, useState } from "react";
import Chat, { ChatBotContext } from "./Chat";

const ContinueButton = (props) => {
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const {setChatbotVisibility}= useContext(ChatBotContext)

  return (
    <div >
      <button style={button} onClick={() =>  {props.actions.dateOption();
         setButtonsDisabled(true);
        }}
         disabled={buttonsDisabled}
         >
        Yes
      </button>
      <button style={button}
        onClick={() => {
        
          setButtonsDisabled(true);
          setChatbotVisibility(false)
        
        }}
        disabled={buttonsDisabled}
      >
        NO
      </button>
    </div>
  );
};
const button = {
  padding: "5px 15px",
        margin: "5px",
        backgroundColor: "#D04848",
        color: "#fff",
        borderRadius: "5px",
        cursor: "pointer",
};

export default ContinueButton;

