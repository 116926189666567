import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

const SuccessBox = ({
  open,
  handleClose,
  message,
  handleYes,
  status,
  text,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{text}</DialogTitle>
      <DialogContent>
        {/* <CheckCircleOutline sx={{ fontSize: 60, color: 'green' }} /> */}
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleYes(status)} variant="contained">
          Yes
        </Button>
        <Button onClick={handleClose} variant="contained">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessBox;
