// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, sendEmailVerification } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDCR70k4C0wwhkA0vrh1XbN4Q-F5RPZ9hc",
  authDomain: "alai-resident-app-b0069.firebaseapp.com",
  projectId: "alai-resident-app-b0069",
  storageBucket: "alai-resident-app-b0069.appspot.com",
  messagingSenderId: "722546228703",
  appId: "1:722546228703:web:dc339d2e2c1d39dfe5186a",
  measurementId: "G-Z10VGCYB5N",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const emailLink = sendEmailVerification;
export default app;
