import React, { useState, useEffect } from "react";
import DataSerices from "../Services/DataSerices";
import {
  Card,
  Typography,
  Grid,
  IconButton,
  TextField,
  MenuItem,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { Button } from "@mui/material";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SuccessBox from "../Components/SuccessBox";
import SuccessToast from "../Components/ToastBox";
import BackDropLoading from "../Components/Loading";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import EditApprovalPopUp from "./EditApprovalPopUp";

const statusList = [
  {
    value: "PENDING",
    label: "Pending",
  },
  {
    value: "ACTIVE",
    label: "Active",
  },
  {
    value: "REJECTED",
    label: "Rejected",
  },
];

const approveText = "Are you sure, you want to approve this user?";
const declineText = "Are you sure, you want to deactivate this user?";
const userRole = `MASTER`;
const initialToast = {
  open: false,
  msg: "",
};

const getMuiTheme = () =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: "red",
          },
        },
      },
    },
  });

function CustomToolbarComponent({ data, handleChange }) {
  const handleSelect = (e) => {
    handleChange(e);
  };
  return (
    <TextField
      id="outlined-select-currency"
      select
      size="small"
      label="Select"
      value={data}
      onChange={(e) => handleSelect(e.target.value)}
    >
      {statusList.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

function Dashboard() {
  const [list, setList] = useState([]);
  const [duplicateList, setDuplicateList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [message, setMessage] = useState({
    status: "",
    msg: "",
  });
  const [page, setPage] = useState(0);
  const [selectValue, setSelectValue] = useState("PENDING");
  const [toast, setToast] = useState(initialToast);

  const onCreateOrEdit = async (val, index) => {
    let x = val == "Create" ? null : list[index];
    let data = {
      status: val,
      data: x,
    };
    await setEditData(data);
    setOpenModal(true);
  };
  const [editData, setEditData] = useState({
    status: "Create",
    data: null,
  });
  const handleModalClose = (e) => {
    setOpenModal(false);
    if (e != null) {
      let data = {
        open: true,
        msg: e,
      };
      setToast(data);
      getList();
    }
  };

  useEffect(() => {
    getList();
  }, [selectValue]);

  /* useEffect(()=>{
       let pageSize = 10;
       let pageNo = page*pageSize;
       let showList = pageNo + 10;
       let x = [...duplicateList]
       let array = [];
       if(x.length <= 10 ){
            array = x;
       }
       else{
         x.length > 0 && x.map((y, i)=>{
            if((i >= pageNo) && (i < showList)){
                array.push(y)
            }
         })
       }
       //setList(array);
      
     },[duplicateList, page]) */

  const getList = async () => {
    setIsLoading(true);
    let data = {
      status: selectValue,
      role: userRole,
    };
    await DataSerices.getUserList(data)
      .then((res) => {
        let userArray = [];
        let response = res && res.data && res.data.data;
        response.length > 0 &&
          response.map((x, i) => {
            userArray.push(x.user);
          });
        setDuplicateList(userArray);
        setList(userArray);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  const handlePopUp = (e, index) => {
    setSelectedIndex(list[index]);
    if (e == "approve") {
      let data = {
        status: e,
        msg: approveText,
      };
      setMessage(data);
      setOpen(true);
    }
    if (e == "decline") {
      let data = {
        status: e,
        msg: declineText,
      };
      setMessage(data);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = async (e) => {
    handleClose();
    let email = selectedIndex && selectedIndex.emailId;
    let approveOrNot = e == "approve" ? true : false;
    let data = {
      emailId: email,
      isApproved: approveOrNot,
      role: userRole,
    };
    setIsLoading(true);
    await DataSerices.updateUser(data)
      .then((res) => {
        if (e == "approve") {
          let approveData = {
            open: true,
            msg: `Approved Successfully...`,
          };
          setToast(approveData);
        }
        if (e == "decline") {
          let declineData = {
            open: true,
            msg: `Declined Successfully...`,
          };
          setToast(declineData);
        }
        getList();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleToastClose = () => {
    setToast(initialToast);
  };

  const handleChange = (e) => {
    setSelectValue(e);
  };

  const options = {
    /*  selectableRows: false,
        search: true,
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        serverSide: false,
        filterType: 'custom',
        responsive: 'vertical',
        page: page,
        onChangePage: (newPage) => {
            setPage(newPage);
        },
        pagination: true,
        count: duplicateList.length,
        rowsPerPage: 3,
        rowsPerPageOptions: [3, 5, 10],
        onSearchChange: (searchText) => {
           if(searchText == null){
                setList(duplicateList)
            }
            else{
                if(duplicateList.length > 1 ){
                    const res = duplicateList.filter(obj => Object.values(obj).some(val => 
                        val !=undefined && val != null && val.toString().toUpperCase().includes(searchText.toString().toUpperCase())
                    ));
                    setList(res)
                }
            }
              
        },   
        customToolbar: () => {
            return (
                <CustomToolbarComponent  handleChange={handleChange}/>
            );
        } */
    filter: false,
    selectableRows: false,
    search: true,
    serverSide: false,
    pagination: true,
    download: false,
    print: false,
    viewColumns: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50],
    onSearchChange: (searchText) => {
      if (searchText == null) {
        setList(duplicateList);
      } else {
        if (duplicateList.length > 1) {
          const res = duplicateList.filter((obj) =>
            Object.values(obj).some(
              (val) =>
                val != undefined &&
                val != null &&
                val
                  .toString()
                  .toUpperCase()
                  .includes(searchText.toString().toUpperCase())
            )
          );
          setList(res);
        }
      }
    },
    customToolbar: () => {
      return (
        <CustomToolbarComponent
          data={selectValue}
          handleChange={handleChange}
        />
      );
    },
  };

  const columns = [
    {
      name: "id",
      label: "S/No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{tableMeta.rowIndex + 1}</span>;
        },
      },
    },
    {
      name: "firstName",
      label: "Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "emailId",
      label: "Email ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "mobileNumber",
      label: "Mobile Number",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "unitNumber",
      label: "Unit Number",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div
                style={
                  {
                    // display: 'flex',
                    // flexDirection: 'row',
                    // alignItems: `flex-start`,
                    // justifyContent: 'space-evenly'
                  }
                }
              >
                {(selectValue == "PENDING" || selectValue == "ACTIVE") && (
                  <Button
                    variant="contained"
                    color="info"
                    style={{ marginLeft: `10px` }}
                    onClick={() => onCreateOrEdit("Edit", tableMeta.rowIndex)}
                  >
                    <EditIcon />
                    Edit
                  </Button>
                )}

                {(selectValue == "PENDING" || selectValue == "REJECTED") && (
                  <Button
                    variant="contained"
                    color="success"
                    style={{ marginLeft: `10px` }}
                    onClick={() => handlePopUp("approve", tableMeta.rowIndex)}
                  >
                    <BeenhereIcon />{" "}
                    {selectValue == "REJECTED" ? `Re-Approve` : `Approve`}
                  </Button>
                )}

                {(selectValue == "PENDING" || selectValue == "ACTIVE") && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: `10px` }}
                    onClick={() => handlePopUp("decline", tableMeta.rowIndex)}
                  >
                    <DeleteForeverIcon />
                    Deactivate
                  </Button>
                )}
              </div>
            </>
          );
        },
      },
    },
  ];

  return (
    <React.Fragment>
      <Card>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={12} md={12}>
                        <Grid container>
                            <Grid item xs={8} sm={8} md={10}>
                                <Typography variant="h6" gutterBottom style={{padding: `20px`}}>
                                    Approval List
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={2} style={{paddingTop: `10px`}}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    size="small"
                                    label="Select"
                                    defaultValue="PENDING"
                                    onChange={(e)=> setSelectValue(e.target.value)}
                                    >
                                    {statusList.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid> */}
          <Grid item xs={12} sm={12} md={12}>
            {isLoading && <BackDropLoading open={isLoading} />}
            <MUIDataTable
              title={"Approval Pending List"}
              data={list}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
        <EditApprovalPopUp
          open={openModal}
          handleClose={handleModalClose}
          data={editData}
        />
      </Card>
      <SuccessBox
        open={open}
        handleClose={handleClose}
        status={message.status}
        message={message.msg}
        text={"Confirm...!"}
        handleYes={handleYes}
      />
      <SuccessToast
        open={toast.open}
        handleClose={handleToastClose}
        message={toast.msg}
      />
    </React.Fragment>
  );
}

export default Dashboard;
