import React, {  useState, createContext } from 'react';

import 'react-chatbot-kit/build/main.css';
 import './Chat.css'
import Chatbot from 'react-chatbot-kit';
import Fab from "@mui/material/Fab"; 
import SmartToyIcon from '@mui/icons-material/SmartToy'; 
import config from './config';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';



export const ChatBotContext = createContext(null);


function Chat() {
  const [isChatbotVisible, setChatbotVisibility] = useState(false);

  const toggleChatbotVisibility = () => {
    setChatbotVisibility((prevVisibility) => !prevVisibility);
  };
 
  const loadMessages =(e) => {
    console.log("Messages checking",loadMessages)
  }

  return (
    <div>
<ChatBotContext.Provider value={{setChatbotVisibility}}>
    <Fab onClick={toggleChatbotVisibility} style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '999' }}>
      <SmartToyIcon />
    </Fab>
   
 
    {isChatbotVisible && (
     <div
     style={{
      position: 'fixed',
       bottom: '40px', // Adjust as needed
       right: '20px', // Adjust as needed
       
     }}
   >
     <div
       style={{
         marginRight: '70px', // Adjust as needed
        
         maxWidth: '400px', // Set maximum width as needed
         width: '100%',
       }}
     >
      <Chatbot
        config={config}
        actionProvider={ActionProvider}
        messageParser={MessageParser}
      
       
      />
      </div>
      </div>

    )}
    </ChatBotContext.Provider>
    </div>
  );
}

export default Chat;







