// MUIListComponent.jsx

import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import config from './config';
import { Link } from 'react-router-dom';
import { Typography, Link as MUILink } from '@mui/material';
import moment from 'moment';


const Item = (props) => {
  const data = config.state.selectedEvents.slice(0, 3);

  
  return (
    <div>
         {data.map((event, index) => (
           <Timeline position="alternate">
          
           <TimelineItem>
             <TimelineOppositeContent
               sx={{ m: 'auto 0' }}
               align="right"
               variant="body2"
               color="text.secondary"
             >
               <Typography variant="h6" component="span">
               {event.channel}
               </Typography>
             </TimelineOppositeContent>
             <TimelineSeparator>
              
               <TimelineDot sx={{color:'red'}}>
                 <CameraAltIcon />
               </TimelineDot>
               <TimelineConnector />
             </TimelineSeparator>
             <TimelineContent sx={{ py: '12px', px: 2 }}>
             {moment(event.time).local().format('DD-MM-YYYY , hh:mm:ss ')}
             
              
             </TimelineContent>
           </TimelineItem>
           
          
           </Timeline>
             ))}
       
      {config.state.selectedEvents.length > 3 && (
                <Typography variant="body2">
                  <MUILink 
                  component={Link} to="/reports/alert-report">
                    View More
                  </MUILink>
                </Typography>
              )}
    </div>
  );
 
};

export default Item;
