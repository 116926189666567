import React, { memo, useCallback, useEffect, useState } from "react";
import { Header } from "./EnvironmenrConstants/Header";
import { StaticText } from "./EnvironmenrConstants/StaticText";
import { Grid, Typography, Box, Chip } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import Checkbox from "@mui/material/Checkbox";
import Badge from "@mui/material/Badge";
import MUIDataTable from "mui-datatables";
import Button from "@mui/material/Button";
import DataSerices from "../Services/DataSerices";
import BackDropLoading from "../Components/Loading";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import moment from "moment";

const EnvironmentalSetUp = ({
  loader,
  messageBack,
  ifchild,
  saveFun,
  nodataFound,
}) => {
  const [list, setList] = useState([]);
  const [loaderSetup, setloaderSetup] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [editMode, setEditMode] = useState(false);
  const [displayName, setdisplayName] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [condo, setCondo] = React.useState("");
  const [dropVal, setDropVal] = React.useState([]);
  const [dashboardValues, setDashboardValues] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const baseTheme = useTheme();
  const getMuiTheme = (baseTheme) =>
    createTheme({
      ...baseTheme,
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              fontWeight: `bold`,
              fontSize: `15px`,
              textAlign: "center",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              textAlign: "center",
            },
          },
        },
        MuiPopover: {
          styleOverrides: {
            paper: {
              // Customize the style of the popover content
              padding: "20px",
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              backgroundColor: "white",
            },
          },
        },
      },
    });

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const handleEditClick = (value, index, event) => {
    setEditMode(true);
    setEditingIndex(index);
    setAnchorEl(event.currentTarget);
    setdisplayName(list[index].displayName || "");
  };

  const handleEsaveClick = () => {
    const updatedList = [...list];
    const updatedItem = { ...updatedList[editingIndex] }; // Replace indexToUpdate with the correct index
    updatedItem.displayName = displayName; // Update displayName in the local copy of the data
    updatedList[editingIndex] = updatedItem;
    setList(updatedList);
    setEditMode(false);
    setAnchorEl(null);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setAnchorEl(null);
  };

  const handleCheckboxChange = useCallback(
    (index, columnName) => {
      if (ifchild) {
        const updatedList = [...list];
        updatedList[index][columnName] = !updatedList[index][columnName];
        setList(updatedList);
      }
    },
    [list]
  );

  const handleSaveClick = async () => {
    loader(true);
    var datas = [];
    list.forEach((item, index) => {
      let apidata = {
        channel: item.channel,
        serviceName: item.serviceName,
        displayName: item.displayName,
        monitoring: {
          fallMonitoring: item.fallMonitoring,
          //fightMonitoring: item.fightMonitoring,
          illegalDumping: item.illegalDumping,
          illegalParking: item.illegalParking,
          loitering: item.loitering,
          maintenanceModelA: item.maintenanceModelA,
          maintenanceModelB: item.maintenanceModelB,
          maintenanceModelC: item.maintenanceModelC,
          maintenanceModelD: item.maintenanceModelD,
          maintenanceModelE: item.maintenanceModelE,
          peopleCountInsideGym: item.peopleCountInsideGym,
          peopleGathering: item.peopleGathering,
          perimeterMonitoring: item.perimeterMonitoring,
          pets: item.pets,
          //visitorCarLotTracking: item.visitorCarLotTracking,
        },
      };

      datas.push(apidata);
    });
    if (datas.length > 0) {
      var response = await DataSerices.handleEnvSetup(datas);
      if (response.status < 250) {
        messageBack(true);
      } else {
        messageBack(false);
      }
      loader(false);
    } else {
      nodataFound("Please Contact admin to enable setup");
      loader(false);
    }
  };

  useEffect(() => {
    if (ifchild) {
      loader(true);
    } else {
      setloaderSetup(true);
    }
    const storedUserDetails = sessionStorage.getItem("userDetails");
    let userEmail = null;
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      var dummy = [];
      parsedUser.organization.map((item, index) => {
        dummy.push({ value: item.orgCode, label: item.orgName });
      });
      setDropVal(dummy);
      setCondo(dummy[0].value);
    }
  }, []);

  useEffect(() => {
    if (condo !== "") {
      const storedUserDetailss = sessionStorage.getItem("userDetails");
      const parsedUsers = JSON.parse(storedUserDetailss);
      if (sessionStorage.getItem("userRole") == "SUPERADMIN") {
        getEnviromentalDetails(parsedUsers.emailId, condo);
      } else {
        getEnviromentalDetails(parsedUsers.emailId, condo);
      }
    }
    handleViewDashboard(condo);
  }, [condo]);

  const handleViewDashboard = async (e) => {
    var responseData = await DataSerices.getDashboardURL(e);
    if (responseData.status === 200) {
      setDashboardValues(responseData.data.response.body[0]?.url);
    }
  };

  const handlecondoChange = async (e) => {
    setCondo(e);
  };

  const getEnviromentalDetails = async (email, org) => {
    await DataSerices.environmentalSetup(email, org)
      .then((res) => {
        if (res && res?.data && res?.data?.response?.body) {
          let details = res.data.response.body;
          setList(details);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        if (ifchild) {
          loader(false);
        } else {
          setloaderSetup(false);
        }
      });
  };

  const date = "20-21-2021";

  const columns = [
    {
      name: "id",
      label: "S/No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{tableMeta.rowIndex + 1}</span>;
        },
      },
    },
    {
      name: "channel",
      label: "Channel",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{}}>
              <span>{value}</span>
              <EditIcon
                style={{
                  fontSize: "15",
                  marginLeft: "4",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
                onClick={(e) => handleEditClick(value, tableMeta.rowIndex, e)}
              />
              <Grid sx={{ ml: -2 }}>
                <Chip
                  label={` ${list[tableMeta.rowIndex].displayName || "N/A"}`}
                  size="small"
                />
              </Grid>
              <Popover
                open={editMode}
                anchorEl={anchorEl}
                onClose={handleCancelClick}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div style={{ padding: "10px" }}>
                  <TextField
                    sx={{}}
                    label="Location Name"
                    variant="standard"
                    value={displayName}
                    onChange={(e) => setdisplayName(e.target.value)}
                  />
                  <Button onClick={handleEsaveClick}>Save</Button>
                  {/* <Button onClick={handleCancelClick}>Cancel</Button>   */}
                </div>
              </Popover>
            </div>
          );
        },
      },
    },
    // {
    //   name: "Alai Name",
    //   label: "Alai Name",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <TextField  variant='standard' type="text" color="success" focused sx={{ width:100}} onChange={() =>
    //           handleCheckboxChange(tableMeta.rowIndex, "Alai Name")
    //         }/>
    //       );
    //     },
    //   },
    // },
    {
      name: "illegalParking",
      label: "Illegal Parking",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value || false}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(tableMeta.rowIndex, "illegalParking")
              }
            />
          );
        },
      },
    },
    {
      name: "illegalDumping",
      label: "Illegal Dumping",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value || false}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(tableMeta.rowIndex, "illegalDumping")
              }
            />
          );
        },
      },
    },
    {
      name: "peopleCountInsideGym",
      label: "People Count Inside Gym",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value || false}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(tableMeta.rowIndex, "peopleCountInsideGym")
              }
            />
          );
        },
      },
    },
    {
      name: "perimeterMonitoring",
      label: "Perimeter Monitoring",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value || false}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(tableMeta.rowIndex, "perimeterMonitoring")
              }
            />
          );
        },
      },
    },
    {
      name: "loitering",
      label: "Loitering",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value || false}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(tableMeta.rowIndex, "loitering")
              }
            />
          );
        },
      },
    },
    {
      name: "pets",
      label: "Pets",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value || false}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() => handleCheckboxChange(tableMeta.rowIndex, "pets")}
            />
          );
        },
      },
    },
    {
      name: "peopleGathering",
      label: "People Gathering",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value || false}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(tableMeta.rowIndex, "peopleGathering")
              }
            />
          );
        },
      },
    },
    {
      name: "fallMonitoring",
      label: "Fall Monitoring",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value || false}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(tableMeta.rowIndex, "fallMonitoring")
              }
            />
          );
        },
      },
    },
    /*{
      name: "fightMonitoring",
      label: "Fight Monitoring",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value || false}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(tableMeta.rowIndex, "fightMonitoring")
              }
            />
          );
        },
      },
    },
    {
      name: "visitorCarLotTracking",
      label: "Visitor Car Lot Tracking",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(
                  tableMeta.rowIndex,
                  "visitorCarLotTracking"
                )
              }
            />
          );
        },
      },
    },*/
    {
      name: "maintenanceModelA",
      label: "Maintenance ModalA",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(tableMeta.rowIndex, "maintenanceModelA")
              }
            />
          );
        },
        display: false,
      },
    },
    {
      name: "maintenanceModelB",
      label: "Maintenance ModalB",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value || false}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(tableMeta.rowIndex, "maintenanceModelB")
              }
            />
          );
        },
        display: false,
      },
    },
    {
      name: "maintenanceModelC",
      label: "Maintenance ModalC",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value || false}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(tableMeta.rowIndex, "maintenanceModelC")
              }
            />
          );
        },
        display: false,
      },
    },
    {
      name: "maintenanceModelD",
      label: "Maintenance ModalD",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value || false}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(tableMeta.rowIndex, "maintenanceModelD")
              }
            />
          );
        },
        display: false,
      },
    },
    {
      name: "maintenanceModelE",
      label: "Maintenance ModalE",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value || false}
              color="success"
              inputProps={{ "aria-label": "controlled" }}
              onChange={() =>
                handleCheckboxChange(tableMeta.rowIndex, "maintenanceModelE")
              }
            />
          );
        },
        display: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <BootstrapTooltip
              title={`${moment(
                Date(list[tableMeta.rowIndex].modifiedDateTime)
              ).format("MMMM Do YYYY, h:mm:ss a")}`}
            >
              <div>
                <LightbulbIcon
                  title="Delete"
                  style={{ color: value == "online" ? "green" : "red" }}
                />
              </div>
            </BootstrapTooltip>
          );
        },
      },
    },
    /*{
       name: "noOfImages",
       label: "Images Processed",
       options: {
         filter: false,
         sort: false,
         customBodyRender: (value, tableMeta, updateValue) => {
                     
           return <Typography>{value}</Typography> 
         },
       },
     },*/
    /*  {
      name: "webpage",
      label: "HealthCheck",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid>
              <Button
                sx={{
                  fontSize: "0.7rem",
                  padding: "1px 5px",
                  marginTop: "25px",
                }}
                onClick={handleOpen}
                variant="contained"
                size="xs"
              >
                View{" "}
              </Button>
              <Grid style={{ marginTop: "1px" }}>{date}</Grid>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 6,
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Text in a modal
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Duis mollis, est non commodo luctus, nisi erat porttitor
                    ligula.
                  </Typography>
                </Box>
              </Modal>
            </Grid>
          );
        },
      },
    }, */
  ];

  const openInNewTab = (url) => {
    const newWindow = window.open(
      dashboardValues,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const options = {
    filter: false,
    filterType: "checkbox",
    selectableRows: "none",
    search: false,
    serverSide: false,
    download: false,
    print: false,
    viewColumns: true,
    pagination: false,
    onColumnViewChange: (changedColumn, action) => {},

    selectableRowsOnClick: true,
  };

  useEffect(() => {
    if (saveFun) {
      handleSaveClick();
    }
  }, [saveFun]);

  return (
    <>
      {list ? (
        <Grid container spacing={2}>
          <Grid item xs={10} sm={10} md={10}>
            {!ifchild && <Header title={StaticText.environmentalSetup} />}
          </Grid>
          {sessionStorage.getItem("userRole") == "SUPERADMIN" && (
            <Grid item xs={2} sm={2} md={2} style={{ padding: `30px` }}>
              <TextField
                id="outlined-select-currency"
                select
                label="Condo Name"
                size="small"
                fullWidth
                value={condo}
                onChange={(e) => handlecondoChange(e.target.value)}
              >
                {dropVal.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {loaderSetup && <BackDropLoading open={loaderSetup} />}

          <Grid item xs={12} sm={12} md={12}>
            <ThemeProvider theme={getMuiTheme(baseTheme)}>
              <MUIDataTable data={list} columns={columns} options={options} />
            </ThemeProvider>
          </Grid>
          {/*  {ifchild && (
            <Grid
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px",
              }}
              item
              xs={12}
              sm={12}
              md={12}
            >
              <Button variant="contained" onClick={handleSaveClick}>
                Save
              </Button>
            </Grid>
          )} */}
          {!ifchild && (
            <Grid
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px",
              }}
              item
              xs={12}
              sm={12}
              md={12}
            >
              <Grid>
                <Button onClick={openInNewTab} variant="contained">
                  View Dashboard
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      border: "2px solid #000",
                      boxShadow: 24,
                      p: 6,
                    }}
                  >
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Text in a modal
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Duis mollis, est non commodo luctus, nisi erat porttitor
                      ligula.
                    </Typography>
                  </Box>
                </Modal>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Typography textAlign="center" fontSize={30} color={"whitesmoke"}>
          {" "}
          Your Setup is being generated
        </Typography>
      )}
    </>
  );
};

export default EnvironmentalSetUp;
