import React, { useState, useEffect } from "react";
import { createContext, useContext } from "react";

import MUIDataTable from "mui-datatables";

import { Grid, Typography, Chip, Button, Toolbar } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import NvrPopup from "./NvrPopup";
import DataSerices from "../Services/DataSerices";
import { toast, ToastContainer } from "react-toastify";

const NvrApproval = () => {
  const [approval, setApproval] = useState([]);
  const [list, setList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState({
    status: "",
    data: "",
  });
  const onEdit = async (val, index) => {
    let data = {
      status: val,
      data: list[index],
    };
    setEditData(data);
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
  };
  const handleSave = () => {
    closeModal();
  };

  const columns = [
    {
      name: "id",
      label: "S/No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{tableMeta.rowIndex + 1}</span>;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "customerId",
      label: "Customer Id",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "emailId",
      label: "Email",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "cameras",
      label: "Number Of Cameras",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "subscriptionStartdate",
      label: "Subscription Start Date",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "subscriptionEnddate",
      label: "Subscription End Date",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Button
                variant="contained"
                color="success"
                style={{ marginLeft: `10px` }}
                onClick={() => onEdit("Edit", tableMeta.rowIndex)}
              >
                <EditIcon />
                Edit
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    download: false,
    print: false,
    onRowClick: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
    filterType: true,
  };

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    let userEmail = null;
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      userEmail = parsedUser.emailId;
    }
    await DataSerices.getAllNvrDetails(userEmail)
      .then((res) => {
        let response = res?.data?.response?.body;
        setList(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <Grid>
      <Grid container spacing={0} direction="column">
        <Grid item xs={12}>
          <MUIDataTable
            title={<div>Nvr Approval </div>}
            data={list}
            columns={columns}
            options={options}
          />
          <NvrPopup
            onSave={handleSave}
            open={openModal}
            handleClose={closeModal}
            data={editData}
            messageBack={({ success, errMsg }) => {
              if (success) {
                toast.success("Data updated sucessfully");
              } else {
                toast.error(errMsg ? errMsg : "Something went wrong");
              }
            }}
          />
        </Grid>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default NvrApproval;
