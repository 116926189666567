const responseBuilder = async (data) => {
  try {
    if (data.status == 200 || data.status < 300) {
      const json = await data.json();
      return json;
    } else if (data.status == 400) {
      const json = await data.json();
      var res = {
        statusCode: data.status,
        statusDescription: json.results[0],
      };
      return res;
    } else {
      var res = {
        statusCode: data.status,
        statusDescription: "Something Went Wrong",
      };
      return res;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const postApi = async (data, urlLink) => {
  try {
    const urlBuilder = urlLink;
    const postResponse = await fetch(urlBuilder, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
      body: JSON.stringify(data),
    });
    return await responseBuilder(postResponse);
  } catch (error) {
    console.error(error);
  }
};

const getAPi = async (urlLink) => {
  try {
    const urlBuilder = urlLink;
    const getResponse = await fetch(urlBuilder, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    });
    return await responseBuilder(getResponse);
  } catch (error) {
    console.error(error);
  }
};

export default {
  getAPi,
  postApi,
};
