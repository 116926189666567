import { createChatBotMessage } from 'react-chatbot-kit';
import Channel from './Channel';
import Avatar from './Avatar';
import Alert from './Alert';
import DateTimePicker from './Date';
import ChannelButton from '../ChannelButton';
import Item from './Items';
import ContinueButton from './ContinueButton';

const config = {
  botName: "360CS",
  initialMessages: [createChatBotMessage(`Welcome to 360CS! Enter a Message to Continue`,)],
  customComponents: {
    botAvatar: (props) => <Avatar {...props} />,
  },
  state: {
    checker: null,
    selectedChannel: [],
    selectedItems: [],
    selectedAlerts: [],
    selectedEvents:[],
    selectedDates: {
      startDate: "",
      endDate: "",
    },
  },
  setSelectedChannel: async () => {
    config.state.selectedChannel = ("");
   
  },
  setAlertData: (data) => {
    config.state.alertData = data;
  },
  setSelectedDates: (startDate, endDate) => {
    config.state.selectedDates = { startDate, endDate };
  },
  setSelectedAlerts: (selectedAlerts) => {
    config.state.selectedAlerts = selectedAlerts;
  },
  setSelectedItems: (selectedItems) => {
    config.state.selectedItems = selectedItems;
  },
  setSelectedEvents:(menuItem) => {
    config.state.selectedEvents  = menuItem
  },
  
  widgets: [
   
    {
      widgetName: "date",
      widgetFunc: (props) => <DateTimePicker {...props} />,
    },
    {
      widgetName: "button",
      widgetFunc: (props) => <ChannelButton {...props} />,
    },
    
    {
      widgetName: "option",
      widgetFunc: (props) => <Channel {...props} />,
    },
    {
      widgetName: "alert",
      widgetFunc: (props) => <Alert {...props} />,
     
    },
    {
      widgetName: "event",
      widgetFunc: (props) => <Item {...props} />,
     
    },
    {
      widgetName: "continue",
      widgetFunc: (props) => <ContinueButton {...props} />,
     
    },
  ]
};

export default config;