import { auth } from "./Firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import http_common from "./http-common";
import request from "./request";

const kewgreenBaseURL = `https://kewgreen.wegosecure.com/api`;

const onSignUp = (data) => {
  return createUserWithEmailAndPassword(auth, data.email, data.password);
};

const onSignIn = (data) => {
  return signInWithEmailAndPassword(auth, data.email, data.password);
};

const onRecover = (data) => {
  return sendPasswordResetEmail(auth, data.email);
};

const onSignOut = () => {
  return signOut(auth);
};

const loginUser = (data) => {
  //user/login user/{type}
  return http_common.post(`user/login`, data);
};

const registerUser = (data, type) => {
  //user/login user/{type}
  return http_common.post(`user/${type}`, data);
};

const handleUserLogin = async (data) => {
  return await request.postApi(data, `${kewgreenBaseURL}/auth/firebase/login`);
};

const handleUserSignUp = async (data) => {
  return await request.postApi(data, `${kewgreenBaseURL}/auth/firebase/signup`);
};

export default {
  onSignUp,
  onSignIn,
  onRecover,
  onSignOut,
  loginUser,
  registerUser,
  handleUserLogin,
  handleUserSignUp,
};
