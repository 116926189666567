import MUIDataTable from "mui-datatables";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DataServices from "../Services/DataSerices";
import BackDropLoading from "../Components/Loading";
import { FormControl, Modal, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

var monthName = new Array(
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
);
var d = new Date();
let monthData = [];
const rowsPerPageOptions = [10, 25, 50, 100];

const category = [
  `All`,
  `Contractor`,
  `Delivery`,
  `Pickup/Dropoff`,
  `Resident`,
  `Unregistered`,
  `Visitor`,
];

export default function EntryExit() {
  const csvLink = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = React.useState([]);
  const [page, setPage] = useState(0);
  const [details, setDetails] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadcsv, setDownloadcsv] = React.useState([]);
  const [inputSearch, setInputSearch] = useState({
    name: "",
    block: "",
    contactNo: "",
    vehicleNo: "",
    monthWise: "",
    category: "",
  });
  const [imageURL, setImageURL] = useState(
    "https://www.shutterstock.com/shutterstock/photos/1938437788/display_1500/stock-photo-jpeg-joint-photographic-experts-group-is-an-group-of-experts-that-develops-and-maintains-standards-1938437788.jpg"
  );
  const [bDownloadReady, setDownloadReady] = useState(false);
  const [OverlayOpen, setOverlayOpen] = useState(false);
  const [progress, setProgress] = useState(false);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
  };
  const userRole = useRef("ADMIN");
  useEffect(() => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    let userEmail = null;
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      userRole.current = parsedUser.roleName;
      onSearch();
    }
  }, [rowsPerPage]);

  const columns = [
    {
      name: "id",
      label: "S/No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span>
              {tableMeta.rowIndex + 1 + rowsPerPage * (currentPage - 1)}
            </span>
          );
        },
      },
    },
    {
      name: "vehicleName",
      label: "Vehicle Number",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "visitorType",
      label: "Category",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "entryTime",
      label: "Entry",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "exitTime",
      label: "Exit",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "unitNo",
      label: "Unit",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "firstName",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "mobileNumber",
      label: "Contact",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "imageURL",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                variant="contained"
                style={{ background: "#DF2626" }}
                onClick={() => {
                  getItemDetails(value);
                }}
              >
                View
              </Button>
            </>
          );
        },
      },
    },
  ];
  const getItemDetails = async (data) => {
    try {
      if (data !== undefined || data !== null) {
        setImageURL(data);
      }
      setOverlayOpen(true);
    } catch (error) {
      console.error(error);
    }
  };
  const options = {
    selectableRows: false,
    search: false,
    count: totalRows,
    page: page,
    filter: false,
    download: false,
    serverSide: true,
    print: false,
    viewColumns: false,
    filterType: "custom",
    responsive: "vertical",
    filterOptions: {
      filter: {
        title: "",
        reset: "",
      },
    },
    searchPlaceholder: "Search by Vehicle Number",
    pagination: list.length > 9 ? true : false,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: rowsPerPageOptions,
    textLabels: {
      filter: {
        title: "",
        reset: "",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          if (list && list.length > 9) {
            changePage(tableState.page);
          }
          break;
        case "search":
          onSearch(tableState.searchText);
          break;
        case "resetFilters":
          break;
      }
    },
    onChangeRowsPerPage: handleRowsPerPageChange,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              backgroundColor: "#D2D3D5 !important",
            },
            paper: {
              boxShadow: "none",
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              "&:nth-child(odd)": {
                backgroundColor: "#D2D3D5 !important",
              },
              "&:nth-child(even)": {
                backgroundColor: "#A7A9AC !important",
              },
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "8px",
              //backgroundColor: '#CDCAC6 ',
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: "#666666 !important",
              color: `white`,
            },
            icon: {
              color: `white ! important`,
              backgroundColor: `white ! important`,
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            head: {
              backgroundColor: "#666666 !important",
              color: `white`,
              //padding: "0px 0px",
              fontWeight: `bold`,
              fontSize: `15px`,
              //justifyContent: 'center'
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "blue !important",
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              backgroundColor: `#666666 !important`,
              "& .MuiToolbar-root": {
                //backgroundColor: 'white',
                color: `white`,
              },
            },
          },
        },
      },
    });

  const CustomTooltip = ({ children, ...props }) => {
    return (
      <Tooltip
        {...props}
        title={props.title === "Filter Table" ? "Search" : props.title}
      >
        {children}
      </Tooltip>
    );
  };

  useEffect(() => {
    monthData = [];
    var x = new Date();
    for (let i = 0; i <= 11; i++) {
      var future = new Date(x.getFullYear(), x.getMonth() - i, 1);
      var month = monthName[future.getMonth()];
      var year = future.getFullYear();
      monthData.push(month + "-" + year);
    }
  }, []);

  function changePage(page) {
    setCurrentPage(page);
    setPage(page);
  }

  useEffect(() => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    let userEmail = null;
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      var dummy = [];
      parsedUser.organization.map((item, index) => {
        dummy.push({ value: item.orgCode, label: item.orgName });
      });
      userRole.current = parsedUser.roleName;
      onSearch();
    }
    //
  }, [page]);

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setProgress(false);
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const SearchByGlobal = async () => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    let userEmail = null;
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      var dummy = [];
      parsedUser.organization.map((item, index) => {
        dummy.push({ value: item.orgCode, label: item.orgName });
      });
      userRole.current = parsedUser.roleName;
      onSearch();
    }
  };

  const handleChange = (e) => {
    console.log(e);
    setInputSearch({ ...inputSearch, [e.target.id]: e.target.value });
  };

  const handleCategoryChange = (e) => {
    console.log(e);
    setInputSearch({ ...inputSearch, category: e.target.value });
  };

  const handleMonthChange = (e) => {
    setInputSearch({ ...inputSearch, monthWise: e.target.value });
  };

  const onSearch = async () => {
    let data = {
      pageNo: page,
      field: inputSearch,
      pageSize: rowsPerPage,
    };
    setIsLoading(true);
    if (userRole.current !== "SECURITY") {
      await DataServices.searchEntryExit(data)
        .then((d) => {
          let data = d && d.data && d.data.data && d.data.data.content;
          let element = d && d.data && d.data.data && d.data.data.totalElements;
          setTotalRows(element);
          setList(data);
          setDetails(arrayToCSV(data));
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          setIsLoading(false);
        });
    } else {
      try {
        const storedUserDetail = sessionStorage.getItem("userDetails");
        if (storedUserDetail) {
          const parsedUsers = JSON.parse(storedUserDetail);
          var dummys = [];
          parsedUsers.organization.map((item, index) => {
            dummys.push({ value: item.orgCode, label: item.orgName });
          });
          data.org = dummys[0].value;
          var resdata = await DataServices.searchEntryExitSecurity(data);
          setTotalRows(resdata.data.response.body.totalElements);
          setList(resdata.data.response.body.content);
          // setDetails(arrayToCSV(data));
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const downloadCsvByApi = async () => {
    if (userRole.current !== "SECURITY") {
      setProgress(true);
      let data = {
        pageNo: page,
        field: inputSearch,
      };
      await DataServices.downloadEntryExitExcel(data).then((res) => {
        let details = res && res.data;
        setDownloadcsv(details);
        setDownloadReady(true);
      });
    }
  };

  function arrayToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    return array.reduce((str, next) => {
      str +=
        `${Object.values(next)
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      return str;
    }, str);
  }

  const handleClose = (e) => {
    setOverlayOpen(false);
    setImageURL("");
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ paddingLeft: `30px`, paddingRight: `30px` }}
        >
          <Modal
            open={OverlayOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: imageURL.length < 1 ? "65%" : "60%",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
              }}
            >
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ padding: `20px`, width: "100%", maxHeight: "80%" }}
              >
                <IconButton
                  aria-label="Close"
                  color="inherit"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                  onClick={() => handleClose()}
                >
                  <CloseIcon />
                </IconButton>
                <Grid item xs={12} sm={12} md={12}>
                  {imageURL.length > 0 && (
                    <img
                      src={imageURL}
                      style={{ width: "100%", maxHeight: `65vh` }}
                      alt="Wait.."
                    />
                  )}
                  {imageURL.length < 1 && (
                    <Typography
                      style={{ width: "100%", maxHeight: `65vh` }}
                      align="center"
                    >
                      No Data found
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Grid container>
            <Grid item xs={11} md={11}>
              <h3
                style={{
                  margin: `0px`,
                  padding: `10px`,
                  fontSize: `20px`,
                  color: `white`,
                }}
              >
                Vehicle Entry Audit
              </h3>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: `30px` }}>
          <Grid container spacing={1}>
            <Grid item md={2}>
              <TextField
                id="name"
                label="Name"
                //placeholder="Placeholder"
                multiline
                size="small"
                onChange={handleChange}
                value={inputSearch.name}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                id="contactNo"
                label="Mobile"
                //placeholder="Placeholder"
                multiline
                size="small"
                onChange={handleChange}
                value={inputSearch.contactNo}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                id="vehicleNo"
                label="Vehicle"
                //placeholder="Placeholder"
                multiline
                size="small"
                onChange={handleChange}
                value={inputSearch.vehicleNo}
              />
            </Grid>
            <Grid item md={2}>
              <FormControl fullWidth>
                <Select
                  value={inputSearch.monthWise}
                  onChange={handleMonthChange}
                  label={"Select Month"}
                  displayEmpty
                  size="small"
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Select Month</em>
                  </MenuItem>
                  {monthData &&
                    monthData.map((x) => <MenuItem value={x}>{x}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <Select
                  value={inputSearch.category}
                  onChange={handleCategoryChange}
                  displayEmpty
                  size="small"
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Select Category</em>
                  </MenuItem>
                  {category &&
                    category.map((x) => <MenuItem value={x}>{x}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={1}>
              <Button variant="contained" onClick={SearchByGlobal} size="large">
                <SearchIcon />
              </Button>
            </Grid>
            <Grid item xs={1} md={1}>
              <Button
                variant="contained"
                size="large"
                onClick={(e) => downloadCsvByApi()}
              >
                <DownloadIcon />
              </Button>
              <CSVLink
                data={downloadcsv}
                filename={`${new Date().getTime()}.csv`}
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid item xs={12} style={{ paddingLeft: `30px`, paddingRight: `30px` }}>
        {isLoading && <BackDropLoading open={isLoading} />}
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            columns={columns}
            data={list}
            options={options}
            components={{
              Tooltip: CustomTooltip,
              icons: {
                FilterIcon: SearchIcon,
              },
            }}
          />
        </ThemeProvider>
      </Grid>
      <Snackbar open={progress} autoHideDuration={3000}>
        <Alert severity="info">Downloading</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
