import React, { useState, useEffect, memo, useRef } from "react";
import {
  Grid,
  Typography,
  Card,
  Button,
  CardContent,
  CardActions,
  Box,
  Backdrop,
} from "@mui/material";
import { useTheme, TextField, MenuItem } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Oval } from "react-loader-spinner";
import patrolJSON from "../../../Constants/Patrol.json";
import ReportsInfo from "../../../Constants/zone";
import SockJsClient from "react-stomp";
import DataSerices from "../../../Services/DataSerices";
import { toast, ToastContainer } from "react-toastify";
import AppConst from "../../../Constants/AppConst";
import CircularProgress from "@mui/material/CircularProgress";

const PatrolStreaming = ({ dropData }) => {
  const theme = useTheme();
  const styles = {
    container: {
      width: "100%",
      height: `65vh`,
    },
    containerOuter: {
      width: "100%",
      height: `65vh`,
      borderWidth: "1px",
    },
  };
  const [displayFeedback, setFeedback] = useState([]);
  const [loading, setLoading] = useState(false);
  const [condo, setCondo] = React.useState("All");
  const [websocketCall, setWebSocketCall] = useState(false);
  const [topics, setTopics] = useState([]);
  const [channelLists, setChannelList] = useState([
    { value: "All", label: "All" },
  ]);

  const [backdropLoad, setBackdropLoad] = useState(false);
  const [imageUrl, setImageURL] = useState("");
  const [questionList, setQuestionList] = useState([]);
  const [selectedId, setSelectedId] = useState("");

  const [selectedValue, setSelectedValue] = useState("");
  const refForValues = useRef(null);
  const webToast = useRef("Start");

  useEffect(() => {
    //API Call
    setLoading(true);

    refForValues.current = condo;
    setTimeout(() => {
      let data = patrolJSON.data;
      setLoading(false);
    }, 3000);
  }, []);
  useEffect(() => {
    GetChannelData();
  }, [dropData]);

  useEffect(() => {
    if (displayFeedback?.length > 0) {
      if (condo === "All") {
        setImageURL(displayFeedback[displayFeedback.length - 1]?.imageUrl);
        setQuestionList([
          ...displayFeedback[displayFeedback.length - 1]?.questionnaire,
        ]);
        setSelectedId(displayFeedback[displayFeedback.length - 1]?.id);
      } else {
        displayFeedback.map((item, value) => {
          if (item?.channel === condo) {
            setImageURL(item?.imageUrl);
            setQuestionList(item?.questionnaire);
            setSelectedId(item?.id);
          } /* else {
            if (
              refForValues.current === null ||
              refForValues.current === undefined
            ) {
              setImageURL("");
              setQuestionList("");
              setSelectedId("");
            }
          } */
        });
      }
    }
  }, [displayFeedback]);

  const GetChannelData = async () => {
    try {
      var channelList = await DataSerices.getChannelList(dropData);
      if (
        channelList &&
        channelList?.data &&
        channelList?.data?.response &&
        channelList?.data?.response?.body
      ) {
        var chaneelListing = [...channelLists];
        channelList?.data?.response?.body.map((item, value) => {
          chaneelListing.push({ value: item.channel, label: item.channel });
        });
        setChannelList(chaneelListing);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ToastMessage = (message, info) => {
    switch (message) {
      case "success":
        toast.success(info, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        break;
      case "error":
        toast.error(info, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        break;
      default:
        return null;
    }
  };

  const onMessageReceived = async (value) => {
    if (value) {
      var data = [];
      if (displayFeedback?.length > 0) {
        if (displayFeedback?.length > 21) {
          var slicedArray = displayFeedback.slice(-20);
          data = [...slicedArray];
        } else {
          data = [...displayFeedback];
        }
      }
      data.push(value);
      setSelectedValue("");
      setFeedback(data);
    }
  };

  useEffect(() => {
    refForValues.current = condo;
    setSelectedValue("");

    if (displayFeedback?.length > 0) {
      if (refForValues.current !== "All") {
        var events = displayFeedback.filter((items, index) => {
          return items?.channel === condo;
        });
        if (events?.length > 0) {
          setImageURL(events[events.length - 1]?.imageUrl);
          setQuestionList([...events[events.length - 1]?.questionnaire]);
          setSelectedId(events[events.length - 1]?.id);
        } else {
          setImageURL("");
          setQuestionList([]);
          setSelectedId("");
        }
      } else {
        refForValues.current = condo;
        setImageURL(displayFeedback[displayFeedback.length - 1]?.imageUrl);
        setQuestionList([
          ...displayFeedback[displayFeedback.length - 1]?.questionnaire,
        ]);
        setSelectedId(displayFeedback[displayFeedback.length - 1]?.id);
      }
    }
  }, [condo]);

  const handleStart = async () => {
    setWebSocketCall(true);
  };
  const handleEnd = async () => {
    setWebSocketCall(false);
    setTopics([]);
  };

  const handleSubmit = async () => {
    var datas = {
      id: selectedId,
      status: AppConst.ISSUERAISED,
      questionnaire: [...questionList],
    };
    let dummyitem = datas.questionnaire.filter((item, index) => {
      if (item?.selectedOption) {
        return item;
      }
    });
    if (dummyitem.length !== datas.questionnaire?.length) {
      ToastMessage("error", "Please select any options");
    } else {
      setBackdropLoad(true);
      await DataSerices.handlePatrolAlert(datas)
        .then((res) => {
          ToastMessage("success", "Data updated successfuly");
          setSelectedValue("");
        })
        .catch((e) => {
          ToastMessage("error", "Something went wrong.");
        })
        .finally((e) => {
          setBackdropLoad(false);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropLoad}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "65vh",
          }}
        >
          <Oval
            height={80}
            width={80}
            color={theme.palette.primary.dark}
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor={theme.palette.secondary.dark}
            strokeWidth={1}
            strokeWidthSecondary={1}
          />
        </div>
      ) : (
        <>
          {displayFeedback && (
            <Grid
              container
              spacing={4}
              style={{ padding: `0px`, marginTop: `-50px` }}
            >
              {websocketCall && (
                <SockJsClient
                  url={"https://kewgreen.wegosecure.com/api/cs360-ws"}
                  topics={[`/cloudsecure360/websocket/alerts/${dropData}`]}
                  onConnect={() => {
                    setTopics([`/cloudsecure360/websocket/alerts/${dropData}`]);
                    if (webToast.current === "Start") {
                      ToastMessage("success", "Patrol monitoring Started");
                      webToast.current = "Stop";
                    }
                  }}
                  onDisconnect={() => {
                    ToastMessage("success", "Patrol monitoring stopped");
                    webToast.current = "Start";
                  }}
                  onMessage={(msg) => onMessageReceived(msg)}
                  debug={false}
                />
              )}

              <Grid item xs={12} sm={6} md={6}>
                {imageUrl.length > 0 && (
                  <img src={imageUrl} style={styles.container} />
                )}
                {imageUrl.length === 0 && (
                  <Box
                    sx={{
                      width: `100%`,
                      height: `65vh`,
                      backgroundColor: "#4B5563",
                      borderRadius: `8px`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      {topics.length > 0
                        ? "Image will appear shortly please wait ..."
                        : "Click start to enable virtual patrol"}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                {imageUrl.length === 0 && (
                  <Box
                    sx={{
                      width: `100%`,
                      height: `65vh`,
                      backgroundColor: "#4B5563",
                      borderRadius: `8px`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography>No data found</Typography>
                  </Box>
                )}
                {imageUrl.length > 0 && (
                  <Card style={styles.container}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          {questionList.length > 0 && (
                            <>
                              {questionList?.map((item, index) => {
                                return (
                                  <Grid container spacing={2} key={index}>
                                    <Grid item xs={12} sm={12} md={12}>
                                      <Typography variant="h5">
                                        {item?.question}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                      <FormControl
                                        sx={{ alignItems: `center` }}
                                      >
                                        <RadioGroup
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          name="radio-buttons-group"
                                          value={selectedValue}
                                          onChange={(e) => {
                                            questionList[0].selectedOption =
                                              e.target.value;
                                            setSelectedValue(e.target.value);
                                          }}
                                        >
                                          <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                          />
                                          {/* 
                                      <FormControlLabel
                                        value="maybe"
                                        control={<Radio />}
                                        label="May be"
                                      /> */}
                                        </RadioGroup>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions style={{ float: "center" }}>
                      {displayFeedback[displayFeedback.length - 1]
                        ?.questionnaire && (
                        <Button
                          onClick={() => handleSubmit()}
                          variant="contained"
                        >
                          Submit
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                )}
              </Grid>
              <Grid item xs={2} sm={6} md={2}>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Channel"
                  size="small"
                  fullWidth
                  value={condo}
                  onChange={(e) => setCondo(e.target.value)}
                >
                  {channelLists?.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={2} sm={6} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => handleStart()}
                >
                  START
                </Button>
              </Grid>
              <Grid item xs={2} sm={6} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => handleEnd()}
                >
                  STOP
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default memo(PatrolStreaming);
