import React, { useEffect, useState } from "react";
import DataServices from "../../Services/DataSerices";
import config from "./config";

const Channel = (props) => {
  const [channelsData, setChannelsData] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    let userEmail = null;
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      userEmail = parsedUser.emailId;
      getChannelDetails(userEmail);
    }
  }, []);

  const getChannelDetails = async (email) => {
    try {
      const response = await DataServices.getChannel(email);

      if (response && response.data && response.data.response.body) {
        const menuItems = response.data.response.body.map((item) => ({
          channel: item.channel,
          display: item.displayName,
        }));

        setChannelsData(menuItems);
      } else {
        setError("API response does not contain expected data.");
      }
    } catch (error) {
      console.error("Error fetching channels data:", error);
      setError("Error fetching data from the API.");
    }
  };

  const toggleChannelSelection = (channel) => {
    const isSelected = selectedChannels.includes(channel);

    if (isSelected) {
      // Remove the channel if already selected
      setSelectedChannels((prevSelected) =>
        prevSelected.filter((selectedChannel) => selectedChannel !== channel)
      );
    } else {
      // Add the channel if not selected
      setSelectedChannels((prevSelected) => [...prevSelected, channel]);
    }
  };

  const buttonsMarkup = channelsData.map((channel1) => (
    <button
      style={{
        padding: "10px 20px",
        margin: "5px",
        backgroundColor: selectedChannels.includes(channel1.channel)
          ? "#7CA3E2" // Selected color
          : "#D04848", // Default color
        color: "#fff",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      key={channel1.channel}
      onClick={() => toggleChannelSelection(channel1.channel)}
    >
      {channel1.channel}
    </button>
  ));

  return (
    <div>
      {buttonsMarkup}
      <div>
        <button
          onClick={() => {
            config.state.selectedChannel = selectedChannels;
            config.state.allChannels = channelsData;
            props.actions.alertOption();
          }}
          style={{
            padding: "6px 14px",
            margin: "5px",
            fontSize: "16px",
            backgroundColor: "#4CAF50",
            color: "#fff",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Channel;
